<template>
  <div style="width: 1200px">
    <div>
      <div style="color: gray;margin: 20px">广州高考网>详情</div>
    </div>
    <div
        style="display: flex;">

      <div style="margin: 10px;padding: 5px 10px; width: 80%;background-color: white;border-radius: 10px">
        <div><h2>{{ data.title }}</h2>
          <div style="text-align: right;color: gray;margin-top: -30px">浏览次数：{{ data.browseCount }}次</div>
        </div>
        <el-divider style="height: 5px"></el-divider>
        <div class="zw">

          <v-md-preview scale :text="data.newsContent"></v-md-preview>
        </div>
      </div>
      <!--      <div>   <el-image-->

      <!--          style="width: 300px;height: 200px;margin: 10px 5px 5px;"-->
      <!--          src="https://img.zcool.cn/community/0184a45d469d29a80120695c39e8eb.jpg?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1280,limit_1/sharpen,100"-->

      <!--          alt="LOGO"-->
      <!--      /></div>-->

    </div>

  </div>

</template>

<script>
import {getConsultById} from "../../../api/front/edu";

export default {
  name: "eduDetail",
  data() {
    return {
      id: "",
      data: {}
    }
  },
  methods: {
    getCourDetail() {
      getConsultById({id: this.$route.params.id}).then(res => {
        this.data = res.data.data;
      })
    }
  },
  mounted() {
    this.getCourDetail();
  }
}
</script>
